<template>
  <div id="advanced-field-management--index">
    <b-tabs
      v-if="tabs.length"
      class="tabs--inner"
      pills
      :value="tabValue"
      @input="handleTabChange"
    >
      <b-tab
        v-for="(item, i) in tabs"
        :key="i"
        :title="$t(item.label)"
      >
        <b-card>
          <div v-if="item.entityId === activeTab">
            <FieldManagementForm
              :visible="showForm"
              :schema-key="item.schemaKey"
              :table-key="item.tableKey"
              :resource-id="resourceId"
              @close="showForm = false"
              @saved="getFields()"
            />
            <div class="mb-2 d-flex">
              <div class="mr-1">
                <b-button
                  :id="`create-${item.entityId}-button`"
                  variant="primary"
                  :class="{
                    'icon-disabled': !canCreate,
                  }"
                  @click="canCreate && toggleModal()"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">{{ $t('Create New') }}</span>
                </b-button>
                <b-tooltip :target="`create-${item.entityId}-button`">
                  {{ canCreate ? $t('Create') : $t('no-action-permission') }}
                </b-tooltip>
              </div>
            </div>
            <b-table-simple
              responsive
              class="fields__table"
            >
              <b-thead>
                <b-tr>
                  <b-th
                    v-for="(header, headerIndex) in tableColumns"
                    :key="headerIndex"
                    :class="header.class"
                  >
                    {{ $t(header.label) }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tr v-if="loading">
                <b-td
                  colspan="100%"
                  class="text-center"
                >
                  <b-spinner class="align-middle" />
                </b-td>
              </b-tr>
              <b-tr v-else-if="!items.length">
                <b-td
                  colspan="100%"
                  class="text-center"
                >
                  {{ $t('No matching records found') }}
                </b-td>
              </b-tr>
              <Draggable
                tag="b-tbody"
                :list="items"
                ghost-class="ghost"
                :animation="150"
                handle=".pz__drag-handle"
                @sort="handleSort"
                @start="onStartRowMove"
                @end="onEndRowMove"
              >
                <b-tr
                  v-for="(field, fieldIndex) in items"
                  :key="fieldIndex"
                  :draggable="canUpdate"
                  class="fields__row"
                >
                  <b-td class="text-nowrap">
                    <p v-if="field.partOfName" style="margin-bottom: 0px; margin-top: -20px; font-size: 10px;">{{ $t('Title') }}</p>
                    <ProzessDragHandle
                      v-if="canUpdate"
                      class="tw-mr-2"
                    />
                    {{ getFieldName(field) }}
                  </b-td>
                  <b-td class="text-nowrap">
                    {{ $t(field.label) }}
                  </b-td>
                  <b-td class="text-nowrap">
                    <div class="d-flex">
                      <div style="width: 2.2rem">
                        <feather-icon
                          :icon="customFieldTypeIcons[field.type]"
                          size="16"
                          class="mr-1"
                        />
                      </div>
                      <span style="white-space: nowrap">
                        {{ $t($case.title(field.type)) }}
                      </span>
                    </div>
                  </b-td>
                  <b-td class="text-nowrap">
                    <b-badge :variant="field.required ? 'primary' : 'gray'">
                      {{ field.required ? `*${$t('Required')}` : $t('Optional') }}
                    </b-badge>
                  </b-td>
                  <b-td class="text-nowrap">
                    <b-badge :variant="field.customField ? 'gray' : 'primary'">
                      {{ field.customField ? $t('Custom Field') : $t('Standard Field') }}
                    </b-badge>
                  </b-td>
                  <b-td class="text-center text-nowrap">
                    <prozess-field-wrapper class="tw-flex-1 tw-pt-3">
                      <span
                        :id="`tooltip-${field.id}`"
                        style="max-width: 200px"
                        class="d-block"
                      >
                        {{ field.id }}
                      </span>
                      <b-tooltip
                        :target="`tooltip-${field.id}`"
                        placement="down"
                      >
                        <span class="d-block">{{ $t('Field ID') }} : {{ field.id }}</span>
                        <span
                          v-if="field.createdByUsername"
                          class="d-block"
                        >
                          {{ $t('Created By') }} : {{ field.createdByUsername }}
                        </span>
                        <span
                          v-if="field.createdDate"
                          class="d-block"
                        >
                          {{ $t('Date Time') }} : {{ $dayjs(field.createdDate).format('YYYY-MM-DD HH:mm') }}
                        </span>
                      </b-tooltip>
                    </prozess-field-wrapper>
                  </b-td>
                  <b-td class="text-center text-nowrap">
                    <feather-icon
                      :id="`edit-${item.entityId}-${fieldIndex}`"
                      icon="EditIcon"
                      size="16"
                      class="mx-1 cursor-pointer"
                      :class="{ 'icon-disabled': !canUpdate }"
                      @click="canUpdate && toggleModal(field.key)"
                    />
                    <b-tooltip
                      :target="`edit-${item.entityId}-${fieldIndex}`"
                      placement="left"
                    >
                      {{ !canUpdate ? $t('no-action-permission') : $t('Edit') }}
                    </b-tooltip>
                    <feather-icon
                      :id="`delete-${item.entityId}-${fieldIndex}`"
                      icon="TrashIcon"
                      size="16"
                      class="mx-1 cursor-pointer"
                      :class="{ 'icon-disabled': !canDeleteField(field) }"
                      @click="canDeleteField(field) && confirmRemoval(field)"
                    />
                    <b-tooltip
                      :target="`delete-${item.entityId}-${fieldIndex}`"
                      placement="left"
                    >
                      {{ deleteTooltip(field) }}
                    </b-tooltip>
                  </b-td>
                </b-tr>
              </Draggable>
            </b-table-simple>
          </div>
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import { Entity, GenericTabs, Schema } from '@/constants/app'
import { customFieldTypeIcons } from '@/constants/customFieldTypes'
import { swalConfirmDeleteOption } from '@/helpers/app'
import ProzessDragHandle from '@/components/shared/ProzessDragHandle.vue'
import advancedFieldMgmtService from '@/services/advancedFieldMgmt'
import FieldManagementForm from '@/views/pages/FieldManagement/FieldManagementForm.vue'

export default {
  name: 'AdvancedFieldManagement',

  components: {
    FieldManagementForm,
    Draggable,
    ProzessDragHandle,
  },

  data() {
    return {
      Entity,
      entities: [],
      tabs: [],
      defaultTab: `${Schema.CRM}.${Entity.COMPANY}`,
      entityId: null,
      showForm: false,
      resourceId: null,
      loading: false,
      items: [],
      originalItems: [],
      tableColumns: [
        { key: 'title', label: 'Title', class: 'disabled' },
        { key: 'label', label: 'Label', class: 'disabled' },
        { key: 'fieldType', label: 'Field Type', class: 'disabled' },
        { key: 'condition', label: 'Condition', class: 'disabled' },
        { key: 'classification', label: 'Classification', class: 'disabled' },
        { key: 'createdTimestamp', label: 'System Generated', sortable: true },

        { key: 'actions', label: 'Actions', class: 'text-center disabled' },
      ],

      sorting: false,
      sortingTimeout: null,
      customFieldTypeIcons,
    }
  },

  computed: {
    canCreate() {
      return this.$can('Update', 'System_Settings')
    },

    canUpdate() {
      return this.$can('Update', 'System_Settings')
    },

    canDelete() {
      return this.$can('Update', 'System_Settings')
    },

    tabValue() {
      return this.tabs.findIndex(({ entityId }) => entityId === this.$route.params.tab)
    },

    activeTab() {
      return this.$route.params.tab || this.defaultTab
    },

    entity() {
      const [schemaKey, tableKey] = this.activeTab.split('.')
      return { schemaKey, tableKey }
    },
  },

  watch: {
    '$route.params': {
      handler(params) {
        if (params.tab === this.activeTab) {
          this.getFields()
        }
      },

      immediate: true,
    },
  },

  async mounted() {
    await this.getEntities()
    await this.setupTabs()
  },

  methods: {
    getFieldName(field) {
      return field.favorite ? `📌 ${field.name}` : field.name
    },
    onStartRowMove(event) {
      this.originalItems = JSON.parse(JSON.stringify(this.items))
    },
    onEndRowMove(event) {
      const row = this.originalItems[event.oldIndex]
      const favoriteGroup = this.originalItems.filter(i => i.favorite)
      const hasTitle = this.items.filter(i => i.partOfName).length > 0

      if (hasTitle && event.newIndex === 0) {
          this.items = JSON.parse(JSON.stringify(this.originalItems))
      } else if (row.partOfName) {
          this.items = JSON.parse(JSON.stringify(this.originalItems))
      } else if (!row.favorite) {
        if (event.newIndex < favoriteGroup.length) {
          this.items = JSON.parse(JSON.stringify(this.originalItems))
        }
      } else if (event.newIndex >= favoriteGroup.length + (hasTitle ? 1 : 0)) {
          this.items = JSON.parse(JSON.stringify(this.originalItems))
      }
    },
    canDeleteField(field) {
      return this.canDelete && field.customField
    },

    confirmRemoval(field) {
      if (!this.canDeleteField(field)) {
        return
      }

      this.$swal(swalConfirmDeleteOption(v => this.$t(v))).then(async result => {
        if (result.value) {
          await this.$async(
            advancedFieldMgmtService.delete({
              schema: this.entity.schemaKey,
              table: this.entity.tableKey,
              id: field.id,
            }),
          )

          this.getFields()
        }
      })
    },

    deleteTooltip(field) {
      if (!this.canDelete) {
        return this.$t('no-action-permission')
      }

      return field.customField ? this.$t('Deactivate') : this.$t('deactivate-standard-field-text')
    },

    async getEntities() {
      const { response } = await this.$async(advancedFieldMgmtService.getAllEntities())

      this.entities = response.data.map(item => ({
        label: item.label,
        schemaKey: item.schema,
        tableKey: item.key,
      }))
    },

    async getFields() {
      this.loading = true
      if (!this.resourceId) this.handleSort()

      const { response } = await this.$async(
        advancedFieldMgmtService.getFieldsByEntity({
          schema: this.entity.schemaKey,
          table: this.entity.tableKey,
        }),
      )
      this.items = response.data
      this.resortItems() // this can be removed ones old sorting are reprocesssed
      this.loading = false
    },
    async resortItems() {
      let hasChanges = false
      const favorites = this.items.filter(i => i.favorite)
      if (favorites.length > 0) {
        this.items = this.items.filter(i => !i.favorite)
        this.items = [...favorites, ...this.items]
        hasChanges = true
      }

      const titles = this.items.filter(i => i.partOfName)
      if (titles.length > 0) {
        titles.forEach(element => {
          element.favorite = true
        })
        this.items = this.items.filter(i => !i.partOfName)
        this.items = [...titles, ...this.items]
        hasChanges = true
      }

      if (hasChanges) {
        this.loading = true
        await advancedFieldMgmtService.reorder(
              {
                schema: this.entity.schemaKey,
                table: this.entity.tableKey,
              },
              {
                // Pass null for Advanced Field Management
                key: null,
                // Makes sure that we only pass unique field keys
                order: Array.from(new Set(this.items.map(field => field.key))),
              },
            )

        this.loading = false
      }
    },
    handleSort() {
      console.log('xxx')
      this.doDebounce(
        'sortingTimeout',
        () => {
          advancedFieldMgmtService.reorder(
            {
              schema: this.entity.schemaKey,
              table: this.entity.tableKey,
            },
            {
              // Pass null for Advanced Field Management
              key: null,

              // Makes sure that we only pass unique field keys
              order: Array.from(new Set(this.items.map(field => field.key))),
            },
          )
        },
        1200,
      )
    },

    handleTabChange(index) {
      this.$router.push({
        name: GenericTabs.FIELD_MANAGEMENT,
        params: {
          tab: this.tabs[index].entityId || this.defaultTab,
        },
      })
    },

    setupTabs() {
      this.tabs = this.$lodash
        .orderBy(this.entities, [entity => entity.label.toLowerCase()])
        .filter(({ schemaKey, tableKey }) => schemaKey !== Schema.CUSTOM_RELATIONSHIP && ![Entity.NOTE_ATTACHED_TO].includes(tableKey))
        .map(entity => ({
          ...entity,
          customEntity: entity.schemaKey === Schema.CUSTOM,
          entityId: `${entity.schemaKey}.${entity.tableKey}`,
        }))
        .sort((a, b) => a.customEntity - b.customEntity)
    },

    toggleModal(key = null) {
      this.resourceId = key ? `${this.entity.schemaKey}.${this.entity.tableKey}.${key}` : null

      this.showForm = true
    },
  },
}
</script>
